<template>
  <div>
    <v-container class=" py-2 " >
      Страница не найдена
    </v-container >
  </div>
</template>

<script>

 
export default {
 
  data: function () {
    return {
     
    }
  },
  methods:{
  
  },
  mounted(){
   
   
  },
  computed: {
 
  },
}
</script>

<style >

</style>