var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"none"}},[_vm._v("\n      "+_vm._s(_vm.role = this.currentUser.role)+"\n    ")]),_vm._v(" "),_c('v-container',{staticClass:"contact_block pt-0"},[_c('div',{staticClass:"aboutTitle d-flex justify-center pb-4"},[_c('h2',[_vm._v("Контакты")])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-center align-center pb-4"},[_c('h4',[_vm._v("Консультирую:")]),_vm._v(" "),_c('div',{staticClass:"body-1 my-2 mb-1"},[_vm._v("                \n                    "+_vm._s(_vm.contacts[0].desc)+"\n                    "),(_vm.role == 'admin')?_c('div',{staticClass:"desc "},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.getcont(0)}}},'v-btn',attrs,false),on),[_vm._v("\n                       ред\n                      ")])]}}],null,false,612945644)},[_vm._v(" "),_c('v-card',{attrs:{"elevation":"2","min-width":"344","max-width":"874"}},[_c('v-form',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","label":""},model:{value:(_vm.econt),callback:function ($$v) {_vm.econt=$$v},expression:"econt"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.contitem(1)}}},[_vm._v("\n                                сохранить\n                              ")])],1)],1)],1)],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"body-1 mt-0 mb-3"},[_vm._v("\n                    "+_vm._s(_vm.contacts[1].desc)+"\n                    "),(_vm.role == 'admin')?_c('div',{staticClass:"desc"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.getcont(1)}}},'v-btn',attrs,false),on),[_vm._v("\n                           ред\n                          ")])]}}],null,false,654222541)},[_vm._v(" "),_c('v-card',{attrs:{"elevation":"2","min-width":"344","max-width":"874"}},[_c('v-form',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","label":""},model:{value:(_vm.econt),callback:function ($$v) {_vm.econt=$$v},expression:"econt"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.contitem(2)}}},[_vm._v("\n                                  сохранить\n                                ")])],1)],1)],1)],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"body-1 mt-0 mb-3"},[_vm._v("\n                      "+_vm._s(_vm.contacts[2].desc)+"\n                    "),(_vm.role == 'admin')?_c('div',{staticClass:"desc"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.getcont(2)}}},'v-btn',attrs,false),on),[_vm._v("\n                           ред\n                          ")])]}}],null,false,729115054)},[_vm._v(" "),_c('v-card',{attrs:{"elevation":"2","min-width":"344","max-width":"874"}},[_c('v-form',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","label":""},model:{value:(_vm.econt),callback:function ($$v) {_vm.econt=$$v},expression:"econt"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.contitem(3)}}},[_vm._v("\n                                  сохранить\n                                ")])],1)],1)],1)],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticStyle:{"position":"relative","overflow":"hidden","width":"100%"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.contacts[0].position)}})]),_vm._v(" "),_c('v-container',[(_vm.role == 'admin')?_c('div',{staticClass:" "},[_vm._v("редактор карты:\n                      "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.getcont(5)}}},'v-btn',attrs,false),on),[_vm._v("\n                       ред\n                      ")])]}}],null,false,1885665609)},[_vm._v(" "),_c('v-card',{attrs:{"elevation":"2","min-width":"344","max-width":"874"}},[_c('v-form',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","label":""},model:{value:(_vm.econt),callback:function ($$v) {_vm.econt=$$v},expression:"econt"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.contitempos(1)}}},[_vm._v("\n                                сохранить\n                              ")])],1)],1)],1)],1)],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_vm._v("\n          разработка сайта: "),_c('a',{attrs:{"href":"https://pixeltech.ru"}},[_vm._v("pixeltech.ru")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }